.section-image .title {
  color: $color-black !important;
}

h1,
h2,
h3,
h4 {
  font-family: $font-secondary;
}
h5,
h6 {
  font-family: $font-primary;
}
p {
  font-family: $font-primary;
}
