.App {
  background-color: $color-grey;
}
.inner {
  margin-bottom: 20px;
  margin-top: 40px;
  box-shadow: 0px 6px 10px -4px rgba(0, 0, 0, 0.25);
}
.outer {
  padding: 20px;
}
