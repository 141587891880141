.section {
  padding: 0px;
}

[class*="features-"],
[class*="team-"],
[class*="projects-"],
[class*="pricing-"],
[class*="testimonials-"],
[class*="contactus-"] {
  padding: 10px;
}

.backgroundWho {
  background-color: $color-grey;
}
.description {
  color: $color-black;
}
.title-text {
  margin: 30px;
  font-size: 48px;
}
