.logoImage {
  display: inline-block;
  width: 400px;
}

@media (min-width: 700px) {
  .col-margin {
    margin-top: 90px;
  }
}
