$primary: #fff;
$color-orange: #ff8000;
$secondary: #f7aa19;
$color-blue: #2699ff;
$color-black: #0a0a0a;
$color-white: #ffffff;
$color-educo: #4274b7;
$color-grey: #f5f6f9;
$color-warning: #ffc107;
$color-danger: #dc3545;

// font
$font-primary: "Open Sans", sans-serif;
$font-secondary: "Bitter", serif;

// Testimonials color complimentary:

$educo: #4274b7;
$blue2: #338bff;
$blue3: #1979f7;
$complimentary: #f7aa19;

// #10416B;
