.referenceImage {
  width: 100%;
  margin: 0;
  padding: 0;
}
.referenceImage2 {
  display: none;
  width: 100%;
  margin: 0;
  padding: 0;
}

.referenceCol1 {
  background-color: $color-white;
  margin: 0;
  padding: 0 !important;
}
.referenceCol2 {
  background-color: $color-white;
  margin: 30px;
  position: absolute;
  margin-bottom: 30px;
  padding: 30px;
}
.background-row {
  background-color: $color-white;
}
.margin-h1 {
  margin-top: 30px;
  color: $color-black;
}
.p-reference {
  font-size: 14px;
  color: $color-black;
  font-family: $font-primary;
  margin: 0 20px;
}
.storyP {
  color: $color-black;
  font-size: 16px;
}
.storyImage {
  width: 250px;
}

@media (min-width: 992px) {
  .referenceImage {
    display: none;
  }
  .referenceImage2 {
    display: flex;
  }
  .referenceCol2 {
    padding: 40px;
  }
  .storyP {
    margin: 0 80px;
  }
}
