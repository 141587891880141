.ourServices {
  margin: 20px;
  background-color: $color-grey;
}
.margin-text {
  margin: 20px;
}

.services {
  margin: 10px;
  background-color: $educo;
  padding: 20px;
  border-radius: 3px;
  box-shadow: 0px 1px 2px 0px lightgrey;
  color: $color-white;
}
.services:hover {
  background-color: #4275b7d8;
  color: $color-white;
}
.h1-heading {
  margin: 30px;
}
@media (min-width: 992px) {
  .services {
    margin: 10px;
  }
}
