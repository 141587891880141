@import "./partials/variables";
@import "./partials/variables";
@import "./partials/navbar";
@import "./partials/base";
@import "./partials/sectionHeader";
@import "./partials/whoarewe";
@import "./partials/features";
@import "./partials/storyreference";
@import "./partials/testimonials";
@import "./partials/aboutHeader";
@import "./partials/team";
@import "./partials/references";
@import "./partials/icons";
@import "./partials/contactForm";
@import "./partials/services";
@import "./partials/vacancys";

@import "./partials/map";
@import "./partials//footer";

// Bootstrap
@import "~bootstrap/scss/bootstrap";
