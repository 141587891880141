.info-title {
  color: $color-black;
  font-family: $font-primary;
}
.description-box {
  font-family: $font-secondary;
  height: 300x;
  padding: 10px;
  background-color: $color-white;
  color: $color-black;
  border-radius: 3px;
  box-shadow: 0px 1px 2px 0px lightgrey;
}
.features-1 {
  background-color: $color-grey;
}
@media (min-width: 768px) {
  .description-box {
    height: 475px;
    margin: 20px 0;
  }
}
@media (min-width: 992px) {
  .description-box {
    height: 350px;
    margin: 20px 0;
  }
}
