.card[data-color="educo"] {
  background-color: $educo;
}

.card[data-color="blue2"] {
  background: $blue2;
}
.card[data-color="blue3"] {
  background: $blue3;
}
.card[data-color="complimentary"] {
  background: $complimentary;
}
.testimonials-3 {
  background-color: $color-grey;
}
