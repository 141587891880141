.logo {
  width: 130px;
  height: auto;
}

.nav_link {
  font-family: $font-primary;
  letter-spacing: 0.5px;
  font-weight: bold;
  font-size: 14px;
  margin-right: 20px;
  color: $color-black;
}
.nav_link:hover {
  text-decoration: none;
  color: $educo;
}
.nav_link:active {
  text-decoration: none;
  color: $complimentary;
}

#navbar-main {
  background-color: $color-white;
}
.navbar {
  padding: 0 !important;
}
.nav-item {
  margin-top: 15px !important;
}
@media (min-width: 992px) {
  .nav-item {
    margin-top: none !important;
  }
}
@media (max-width: 767px) {
  .navbar-nav li a,
  .navbar {
    color: $color-black !important;
    opacity: 1 !important;
  }
}
