.section-image:before {
  background: $color-white;
}

.card {
  border: none !important;
}

.section-image .title,
.section-image .info-title,
.section-image .card-plain .card-title,
.section-gold .title,
.section-gold .info-title,
.section-gold .card-plain .card-title,
.section-dark .title,
.section-dark .info-title,
.section-dark .card-plain .card-title {
  color: $color-black !important;
}

.card-categoy {
  color: $color-black !important;
}
.section-image .category,
.section-image .description,
.section-image .card-plain .card-description,
.section-image .card-plain .card-category,
.section-gold .category,
.section-gold .description,
.section-gold .card-plain .card-description,
.section-gold .card-plain .card-category,
.section-dark .category,
.section-dark .description,
.section-dark .card-plain .card-description,
.section-dark .card-plain .card-category {
  color: rgba(0, 0, 0, 0.5) !important;
}
