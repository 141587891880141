.carousel-control {
  background-color: $secondary !important;
}
.carousel-indicators .active {
  background-color: $color-white;
}
.btn-secondary {
  background-color: $educo !important;
  color: $color-white !important;
  border: none !important;
}
.buttons {
  text-decoration: none;
}
.buttons:hover {
  text-decoration: none;
  color: $color-white !important;
}
a:hover {
  text-decoration: none !important;
}
.carousel-indicators li {
  height: 25px !important;
  width: 25px !important;
  margin: 5px !important;
  border-radius: 50%;
}
.custom-links {
  font-family: $font-primary;
  color: $color-white;
}

.page-carousel:hover .carousel-control-prev,
.page-carousel:hover .carousel-control-next {
  display: none;
}

// @media (min-width: 991px) {
//   .page-carousel:hover .carousel-control-prev,
//   .page-carousel:hover .carousel-control-next {
//     display: block;
//   }
// }
