footer {
  padding: 40px;
  background-color: $educo;
  // border-radius: 0 30% 0 0/ 0 70% 0 0;
  color: $color-white;
}
.footer-logo {
  max-width: 200px;
}
.flexboks-link {
  font-family: $font-primary;
  color: $color-warning;
}
.footer-links {
  font-family: $font-primary;
  color: $color-white;
}
