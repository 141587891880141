.btn-primary {
  color: $color-white !important;
  background-color: $color-blue !important;
}

.form-check .form-check-sign::before {
  background-color: lightgrey;
}

.contactus1 .section-image::before {
  background-color: lightblue;
}
.section-image:before {
  background-color: $color-grey;
}
